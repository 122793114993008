/*
 *
 * Filename: logs.filter.tsx
 * Date: 2023-05-11
 * Description: Filters for logs table..
 * Author: Philippe Leroux @SKITSC
 *
 */
import { Box , Typography , IconButton , TextField, MenuItem  } from "@mui/material"
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ClearIcon from '@mui/icons-material/Clear';

//Components and imports
import { callers , types } from "../../utility/constant";

//Interfaces && types
import { i_logs_filter } from "../../interface/logs.interface";
interface ILogsFilters {
    search : i_logs_filter,
    clearFilter : () => void,
    handleTimestamp : ( ) => void;
    handleChangeType : ( event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleChangeCaller : ( event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
const LogsFilters = ( props : ILogsFilters ) => {
    return(
        <Box display={'flex'} sx={{ height : 60}}>
            <Typography sx={{ alignSelf : 'center'}}>Index </Typography>
            <IconButton onClick={() => props.handleTimestamp()} sx={{ alignContent : 'center' , marginRight : '1vh'}}> {props.search.timestamp === 'ASC' ? <TrendingDownIcon/> : <TrendingUpIcon/> }</IconButton>
           
            <TextField select id={'search caller'} sx={{ width : '15em' , marginRight : '1vh'}} value={props.search.caller} onChange={(e) => props.handleChangeCaller(e)} label="Caller :">
                { callers.map((caller) => ( 
                    <MenuItem key={caller} value={caller}>{caller}</MenuItem>
                ))}
            </TextField>
            <TextField select id={'search type'} sx={{ width : '15em'}} value={props.search.type} onChange={(e) => props.handleChangeType(e)} label="Output :">
                { types.map((type) => ( 
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
            </TextField>
            <IconButton sx={{ marginLeft : 'auto'}} onClick={() => props.clearFilter()}><ClearIcon/></IconButton>
        </Box>
    )
}

export default LogsFilters