/*
 * Date: 2024
 * Description: Menu used threw all pages , consider routing of the app.
 * Author: Philippe Leroux @ SKITSC
 */

// Modules
import { ReactElement, useContext , useEffect , useState } from "react"
import { useNavigate , useLocation } from "react-router";
import { Box , Tabs , Tab } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';

//Context
import { MainContext } from "../../context/main.context";

//Theme
import { c_theme } from "../../theme/custom.theme";

//Styles
import { sticky_menu , normal_menu , tab_style , tab_float} from "../styles/styles";
import '../../styles/main.css'

//Utility
import { routes_arr } from "../../utility/constant";

//Components
import Copyright from "../copyright";

const Menu = ( { MyComponent } : any) : ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { menu_pos , setMenuPos } = useContext(MainContext)
    const [ sticky , setSticky ] = useState<boolean>(false)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setMenuPos(newValue);
    };

    useEffect(()=> {
        var number = routes_arr.filter(x => x.val === location.pathname)
        if(number[0].page !== menu_pos)
        setMenuPos(number[0].page)
    },[location , setMenuPos , menu_pos])
    interface LinkTabProps {
        label?: string;
        href?: string;
        sx?: any;
    }
      function LinkTab(props: LinkTabProps) {
        return (
          <Tab
            component="a"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                event.preventDefault();
                if(props.href !== undefined) navigate(props.href)
            }}
            {...props}
          />
        );
      }
    return (
        <Box sx={{minHeight: '100vh'}}>
            <ThemeProvider theme={c_theme}>
                <Box sx={sticky ? sticky_menu : normal_menu}>
                    <Tabs value={menu_pos} onChange={handleChange} aria-label="nav tabs example">
                        <LinkTab label="Dashboard" href="/dashboard" sx={tab_style}/>
                        <LinkTab label="Records *" href="/records"  sx={tab_style}/>
                        <LinkTab label="Endpoints" href="/endpoints"  sx={tab_style}/>
                        <LinkTab label="Settings" href="/setting"  sx={tab_style}/>
                        <LinkTab label="Logs" href="/logs"  sx={tab_style}/>
                        <LinkTab label="Logout" href="/logout" sx={tab_float}/>
                    </Tabs>
                </Box>
            </ThemeProvider>
            <Box  sx={sticky ? {paddingTop : '3em'} : {}}> </Box>
            <Box sx={{}}>
                    {MyComponent}
            </Box>
            <Copyright />
        </Box>
    )
}

export default Menu