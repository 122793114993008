/*
 *
 * Filename: main.context.tsx
 * Date: 2022/10/31
 * Description: Main context set up
 * Author: Philippe Leroux @SKITSC
 *
 */

import React from "react";
import { i_page } from "../interface/page.interface";
import { i_user } from '../interface/user.interface';
import { NavigateFunction } from "react-router-dom";


//Context interface
export interface i_main_context {
    user : i_user,
    authenticated : boolean,
    menu_pos : number,
    loaded : boolean,
    pageContext : i_page,
    totalPage : number,
    setMenuPos : ( pos : number ) => void;
    setPageContext : ( { page , filter , pageSize } : i_page )  => void;
    setUser : ( { first_name , last_name , email , id , UUID , type , phone , password } : i_user) => void;
    setTotalPage : ( CurrentTotalPage : number ) => void;
    setCurrentAuth : (currentAuth : boolean) => void;
    HandleLogin : (currentUser : i_user ) => void;
    HandleLogout : ( nav : NavigateFunction ) => void;
    setLoaded : (CurrentLoaded : boolean) => void;
}
export interface i_auth_context {
    authenticated : boolean,
    setCurrentAuth : ( currentAuth : boolean ) => void;
}
//Default Context State
export const DefaultContext : i_main_context = {
    user : { first_name : '' , last_name : '' , email : '' , id : 0 , UUID : '' , type : 'Worker' , phone : '' , password : ''},
    authenticated : false,
    menu_pos : 0,
    loaded : false,
    pageContext : { page : 0 , filter : '' , pageSize : 25},
    totalPage : 0,
    setMenuPos : () => {},
    setUser : () => {},
    setTotalPage : () => {},
    setPageContext : () => {},
    setCurrentAuth : () => {},
    HandleLogin : () => {},
    HandleLogout : () => {},
    setLoaded : () => {},
}

export const MainContext = React.createContext<i_main_context>(DefaultContext)