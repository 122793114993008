
//Modules
import { ThemeProvider , Paper, TextField } from "@mui/material"
import { useContext , useState , useEffect, ChangeEvent } from "react";

//Context
import { SocketContext } from "../context/socket.context";

//Intefaces
import { i_socket_response , i_alert } from "../interface/utility.interface";
import { i_endpoint, i_endpoint_errors, i_endpoint_form_props, i_endpoint_tbl_props } from "../interface/endpoint.interface";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../context/main.context";

//Components
import PromptAlert from "../components/utils/alert";
import ModalForm from "../components/modal/modal";
import EndpointTbl from "../components/table/endpoints.tbl";

//Constants
import { default_endpoint , default_errors } from "../utility/constant";

//Theme
import { c_theme } from "../theme/custom.theme";
import { Fetch } from "../components/api/fetch";

//Middlewares
import { m_valid_name, m_validate_endpoint } from "../components/utils/field.validation";


const Endpoints = ( props : any ) => {
    const socket = useContext(SocketContext)
    const { HandleLogout } = useContext(MainContext)
    const [ open , setOpen ] = useState<boolean>(false)
    const [ search , setSearch ] = useState<string>('')
    const [ filtered_data , setFilteredData ] = useState<i_endpoint[]>([])
    const [ event , setEvent ] = useState<'endpoint' | 'endpoint_info'>('endpoint')
    const [ form , setForm ] = useState<i_endpoint>(default_endpoint)
    const [ errors , setErrors ] = useState<i_endpoint_errors>(default_errors)
    const [ data , setData ] = useState<i_endpoint[]>([])
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ alert , setAlert ] = useState<boolean>(false)
    const [ alert_msg , setAlertmsg ] = useState<i_alert>({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'});
    const nav = useNavigate()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const response = await Fetch('/api/endpoints' , null, "GET" , props)
            if(response.type === 'Success'){
                setData(response.data)
                setFilteredData(response.data)
            }else{
                if(response.type === 'Unauthorized'){
                    HandleLogout(nav)
                }else{
                    setAlertmsg({...alert_msg, msg : response.message, severity : 'error'})
                    setAlert(true)
                }
            }
            setLoading(false)
        }
        getData()
    },[ nav , HandleLogout ])
    useEffect(() => {
        socket.removeAllListeners("endpoints");
        socket.on('endpoints', ( output : i_socket_response ) => {
            if(output.type === 'Update'){
                const updateItem = ( ItemUpdated : i_endpoint ) => {
                    const data_to_update = [ ...data ] 
                    const updatedItems = data_to_update.map( ( item : i_endpoint, i : number) => {
                        if (item.id === ItemUpdated.id) {
                            return ItemUpdated
                        } else {
                        return item
                        }
                    })
                    setData(updatedItems)
                } 
                updateItem(output.item)
            }
            if(output.type === 'Delete'){
                const DeleteItem = ( ItemDeleted : i_endpoint ) => {
                    const data_to_delete = [ ...data ] 
                    const index = data_to_delete.findIndex(( row : i_endpoint ) => row.id === ItemDeleted.id );
                    if(index > -1){
                        data_to_delete.splice(index,1)
                        setData(data_to_delete)
                    }
                } 
                DeleteItem(output.item)
            }
        })
    },[socket , data])
    const handleCallback = ( row : i_endpoint , type : 'edit' | 'info') => {
        setEvent(type === 'edit' ? 'endpoint' : 'endpoint_info')
        setForm(row)
        setOpen(true)
    }
    
    const handleSubmit = async( ) => {
        const [ valid , message , field ] = m_validate_endpoint(form)
        if(valid){
            const updated_endpoint = { id : form.id, pair_number : form.pair_number, notes : form.notes }
            const res = await Fetch('/api/endpoint' , updated_endpoint , "PATCH" , props)
            if(res.type === 'Success'){
                setOpen(false)
                setForm(default_endpoint)
            }else{
                if(res.type === 'Unauthorized'){
                    HandleLogout(nav)
                }else{
                    setAlertmsg({...alert_msg, msg : res.message, severity : 'error'})
                    setAlert(true)
                }
            }
        }else{
            setErrors({...errors, [field] : message})
        }
    }
    const handleAction = () => {
        
    }
    useEffect(() => {
        if(errors.notes !== '' && m_valid_name(form.notes) === '' ) setErrors({...errors, notes : ''})
        if(errors.pair_number!== '' && m_valid_name(form.pair_number) === '' ) setErrors({...errors, pair_number : ''}) 
    },[form, errors])
    useEffect(() => {
        setFilteredData(data.filter( ( item : i_endpoint ) => item.alias.toLowerCase().includes(search.toLowerCase()) ))
    },[data , search])
    const endpoint_tbl_props : i_endpoint_tbl_props = {
        data : filtered_data,
        count : data.length,
        loading : loading,
        callback : handleCallback
    }
    const form_props : i_endpoint_form_props = {
        form : form,
        setForm : setForm,
        title : "Update endpoint details",
        loading : loading,
        errors : errors,
        callback : handleSubmit
    }
    const modal_props = {
        open : open,
        event: event,
        data : form,
        new_close : true,
        Action : handleAction,
        title : event === 'endpoint' ? "Edit this endpoint" : "Endpoint info",
        loading : loading,
        handleClose : () => setOpen(false),
        form : form_props
    }
    const handleSearch = (e : ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value;
        setSearch(searchTerm);
    }
    return (
        <ThemeProvider theme={c_theme}>
        {alert && <PromptAlert {...alert_msg}></PromptAlert> }
            <Paper sx={{padding : '3vh' , mt : '3vh' , maxWidth : 1300 , display : 'block', marginLeft : 'auto' , marginRight : 'auto' , height: 1050}}>
                <TextField label={'Search : '} sx={{ width : 300 , marginBottom : '1vh'}}value={search} onChange={handleSearch}/>
                    <EndpointTbl {...endpoint_tbl_props} ></EndpointTbl>
            </Paper>
        <ModalForm {...modal_props}></ModalForm>
    </ThemeProvider>
    )
}

export default Endpoints