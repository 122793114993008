/*
 * Date: 2024
 * Description: Records note form
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { Fragment, ReactElement } from "react";
import { Box , Typography , TextField, Grid2 , InputAdornment , IconButton, CircularProgress, Tooltip } from "@mui/material";

//Interfaces
import { i_note_form_props } from "../../interface/utility.interface";
import { i_record_notes } from "../../interface/records.interface";
import { f_timestamp_to_date } from "../../utility/utility";

//Icons
import AddIcon from '@mui/icons-material/Add';


const NoteForm = ( props : i_note_form_props ) : ReactElement => {
    return(
        <Box component={'form'} onSubmit={props.callback}>
            <Tooltip title={"Here we could have a more efficient way to display unicity of a call. What about giving a name to call like ? who or what?"}>
            <Typography variant={'h6'} component={'h6'} align="center" sx={{ marginBottom : '1vh'}}>{props.title}</Typography>
            </Tooltip>
            <Box sx={{ padding : '8px' , border : '2px solid black' , marginBottom : '1vh'}}>
                <Box sx={{ padding : '6px' , alignItems : 'center'}}>
                    <Typography sx={{ textAlign : 'center' , fontWeight : 700}}>{props.sub_title}</Typography>
                </Box>
                <Box mb={3} sx={{}}>
                    {props.target.note !== '' &&
                     <Grid2>
                        <Typography sx={{ textAlign : "center"}}>Old note's system</Typography>
                         <Grid2 size={9} p={1}>
                             <Typography sx={{ fontSize : 18 , fontWeight : 700}}>{props.target.note}</Typography>
                         </Grid2>
                         <Grid2 size={3} p={1}>
                             <Typography sx={{ marginLeft : 'auto' , textAlign : 'right' , fontWeight : '700'}}>
                                 Old note
                             </Typography>
                         </Grid2>
                         <Grid2 size={12} sx={{ borderBottom : '1px solid #3333'}}></Grid2>
                    </Grid2>
                    }
                    {props.target.notes.length > 0 ?
                        <>
                            <Typography sx={{ textAlign : "center"}}>Notes ({props.target.notes.length})</Typography>
                            <Grid2 container p={2} sx={{ border : '1px solid #3333',  maxHeight : '400px' , overflowY : 'auto' }}>
                                <Grid2 size={12}></Grid2>
                              
                                {props.target.notes.map(( item : i_record_notes , index : number ) => (
                                <Fragment key={index} >
                                    <Grid2 size={9} p={1}>
                                        <Typography sx={{ fontSize : 18 , fontWeight : 700}}>{item.note}</Typography>
                                    </Grid2>
                                    <Grid2 size={3} p={1}>
                                        <Typography sx={{ marginLeft : 'auto' , textAlign : 'right' , fontWeight : '700'}}>
                                            {f_timestamp_to_date(item.timestamp , 'long')}
                                        </Typography>
                                    </Grid2>
                                    <Grid2 size={12} sx={{ borderBottom : '1px solid #3333'}}></Grid2>
                                </Fragment>
                                ))}
                            </Grid2>
                        </>
                        : <Box>No notes yet.</Box>
                    }
                </Box>
            </Box>
            <TextField autoFocus label={'Add a new note :'} helperText={props.error} error={props.error !== '' ? true : false} fullWidth value={props.note} onChange={( e ) => props.setNote(e.target.value) }  InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {props.loading ? 
                    <CircularProgress/>
                    :
                    <IconButton onClick={() => props.callback()}>
                      <AddIcon color={'primary'} />
                    </IconButton>
                    }
                  </InputAdornment>
            ),}}></TextField>
        </Box>
    )
}

export default NoteForm;