/*
 * Date: 2024
 * Description: Validation for login fields.
 * Author: Philippe Leroux @ SKITSC
 */
//Utils
import { lexique } from "../../utility/constant"

//interfaces
import { i_endpoint } from "../../interface/endpoint.interface";
const m_valid_email = ( email : string ) => {
    if(email.toLowerCase().match(lexique.email_regex)) return '';
    return lexique.email_error;
}

const m_valid_password = ( password : string ) => {
    if(password.match(lexique.password_regex)) return '';
    return lexique.password_error;
}

const m_valid_Cpassword = ( password : string , confirmPassword : string ) => {
    if(password !== confirmPassword) return lexique.password_match_error
    return ''
}

const m_valid_code = ( code : string ) => {
    if(code.match(lexique.alpha_regex)) return '';
    return lexique.alpha_error
}

const m_valid_name = ( str : string ) => {
    if(str.match(lexique.str_regex)) return '';
    return lexique.str_regex_error
}

const m_valid_phone = ( phone : string ) => {
    if(phone.match(lexique.phone_regex)) return '';
    return lexique.phone_error
}

const m_valid_address = ( address : string ) => {
    if(address.match(lexique.address_regex)) return '';
    return lexique.address_error
}

const m_validate_endpoint = ( endpoint : i_endpoint ) : [ boolean , string , 'notes' | 'pair_number'] => {
    if(m_valid_name(endpoint.pair_number) !== '') return [ false, lexique.str_regex_error, 'pair_number'  ];
    if(m_valid_name(endpoint.notes) !== '') return [ false, lexique.str_regex_error, 'notes'  ];
    return [ true , '' , 'notes' ]
}

export { m_valid_email , m_valid_password , m_valid_Cpassword , m_valid_code , m_valid_name , m_valid_phone , m_valid_address , m_validate_endpoint }