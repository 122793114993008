
/*
 * Date: 2024
 * Description: Card component for the dashboard
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { Box , keyframes , IconButton } from "@mui/material"
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const Bubble = ( props : any ) => {
    const rotate = keyframes`
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-15deg); }
    50% { transform: rotate(15deg); }
    75% { transform: rotate(-15deg); }
    100% { transform: rotate(0deg); }
  `;
    return (
        <>
            { props.bubble &&
                <Box sx={{ position : 'absolute' , bottom : 100 , zIndex : 10000000 , right : 20 , border : '1px solid gray' , background : 'white' , padding : '6px' ,  borderRadius : '24px'}}>
                    <IconButton onClick={() => props.callback()}>
                        <NotificationsActiveIcon sx={{ fontSize : 50 , color : 'red' , animation: `${rotate} 2s infinite` , cursor : 'pointer'}}/>
                    </IconButton>
                </Box>
            }
        </>
    )
}

export default Bubble