/*
 *
 * Filename: logout.tsx
 * Date: 2022-11-03
 * Description: Logout page
 * Author: Philippe Leroux @ SKITSC
 *
 */
import { f_logout } from "../components/api/fetch";
import { useNavigate } from "react-router-dom";
import { useEffect , useContext} from "react";
import { MainContext } from "../context/main.context";
import { c_theme } from "../theme/custom.theme";
import { ThemeProvider } from '@mui/material/styles';
import Loader from "../components/utils/loader";
const Logout = ( props : any ) => {
    const nav = useNavigate();
    const { HandleLogout } = useContext(MainContext)
    useEffect(() => {
        const TriggerLogout = async() => {
            await f_logout();
            HandleLogout(nav);
        }
        TriggerLogout();//eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return  (
        <ThemeProvider theme={c_theme}><Loader/></ThemeProvider>
    )
}

export default Logout;