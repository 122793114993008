/*
 * Date: 2024
 * Description: All resuable constants
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Interfaces
import { i_records , i_filter_records } from "../interface/records.interface"
import { i_endpoint_errors , i_endpoint } from "../interface/endpoint.interface"
import { i_user } from "../interface/user.interface"
import { i_domain } from "../interface/domain.interface"
import { t_log , t_log_output } from "../types/types"

const default_target : i_records = {
    id : 0,
    call_uuid : '',
    add_time : 0,
    recording_url : "",
    recording_duration : 0,
    recording_start_ms : 0,
    recording_end_ms : 0,
    from_number : '',
    to_number : '',
    note : '',
    notes : [],
    events : [],
    timestamp : 0,
    downloaded : 0,
    path : '',
    active : 0,
    input : 0
}
const default_search : i_filter_records = {
    pages : 0 , to_number : '' , from_number : '' , offset : 25 , timestamp : 'DESC' , note : '' , call_uuid : '' , date_range : { start : 0, end : 0 }
}
const default_user : i_user = {
    first_name : '' , last_name : '' , email : '' , id : 0 , UUID : '' , type : 'Admin' , phone : '' , password : ''
}
const default_domain : i_domain = {
    address : '' , timestamp : 0 , id : 0 , note : ''
}
const default_errors : i_endpoint_errors = {
    id : '',
    pair_number : '',
    notes : ''
}
const default_endpoint : i_endpoint = {
    id : 0,
    endpoint_id: '',
    sip_url: '',
    alias: '',
    registered: '',
    application: '',
    username: '',
    sub_account: '',
    password: '',
    pair_number: '',
    notes: '',
    timestamp: 0
}
const ItemPerPage = [
    { value : 5 , option : 5 },{ value : 15 , option : 15 } , { value : 25 , option : 25 } 
    , { value : 50 , option : 50 } , { value : 100 , option : 100 }
 ]
const lexique = {
    address_regex: /^[^\\&@{}=-?<>]*$/,
    address_error : 'Please dot not use &@{}=-?<>',
    str_regex :/^[^\\&={}<>|[]*$/,
    str_regex_min :/^[^\\&@.,()=?{}<>]{5,}$/,
    password_two_regex : /^[a-zA-Z0-9,.:;]{5,}$/,
    password_regex : /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
    email_regex :  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone_regex : /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    alpha_regex : /^[A-Za-z0-9]+$/i,
    alpha_error : 'Please use only alphanumeric caracters',
    str_regex_error : 'Please use caracters other than $={}<>[]|',
    password_two_error : 'Please provide at least 5 characters only a-zA-Z0-9,.:; chars allowed',
    phone_error : "Please provide a valid phone number , ex : 1-514-111-1111",
    special_error : 'cannot contain special characters.',
    email_error : 'email should match email patern ex : example@example.com',
    numeric_error : 'only accepts numeric values ex : 0 , 12',
    empty_error : 'Please provide a value to ',
    password_error : 'must have between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character!',
    password_match_error : 'Password must match confirm password !'
}
const callers : t_log[] = ['Any','Login','Logout','Plivo_error','Records','Scripts','Users','Recovery']
const types : t_log_output[] = ['Any','Success','Error','Warning','Info']
const routes_arr = [
    {
        val : '/dashboard',
        page: 0
    },
    {
        val : '/records',
        page: 1
    },
    {
        val : '/endpoints',
        page: 2
    },
    {
        val : '/setting',
        page: 3
    },
    {
        val : '/logs',
        page: 4
    },
    {
        val : '/logout',
        page: 5
    }
]
export { default_target, default_search , default_user , default_domain , default_endpoint , default_errors , ItemPerPage , lexique , callers, types , routes_arr }