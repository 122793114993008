/*
 *
 * Filename: offline.tsx
 * Date: 2022-11-03
 * Description: Page to show user that the server is offline. ( ' Backend ' )
 * Author: Philippe Leroux @ SKITSC
 *
 */
import Box from '@mui/material/Box';
import { c_theme } from '../theme/custom.theme';
import { ThemeProvider } from '@emotion/react';
import { Container , Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const OfflineBox = ( { offline } : any) => {
    const nav = useNavigate();
    useEffect(() => {
        if(!offline) nav('/login')
    },[offline , nav])
  return (
    <ThemeProvider theme={c_theme}>
        <Container style={{ }} component="main" maxWidth="xs">
            <Box
            mt={20}
            sx={{
                width: 400,
                height: 200,
                p:6,
                backgroundColor: '#555',
                '&:hover': {
                backgroundColor: '#d9b451;',
                color:'white',
                opacity: [0.9, 0.8, 0.7],
                },
            }}
            >
            <Typography mb={5} style={{ textAlign : 'center' , fontSize: '20px' , color:'white' , fontWeight:900}}>Server Issue !</Typography>
            <Typography style={{ color : 'white'}}>Server is currently down , please contact an admin ...</Typography>
            <Typography style={{ color : 'white'}}>This page will refresh if the server goes up.</Typography>
            </Box>

        </Container>
    </ThemeProvider>
  );
}
export default OfflineBox