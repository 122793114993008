/*
 *
 * Filename: filter.table.tsx
 * Date: 2023-05-03
 * Description: Custom grid for filters and search
 * Author: Philippe Leroux @SKITSC
 *
 */

//Modules
import formatDuration from 'format-duration';
import { f_timestamp_to_date , f_format_phone_cute , handlePhone } from '../../utility/utility';
import { IconButton , Box , Table , TableBody , TableCell , TableContainer ,  TableRow , TableHead, Tooltip , Paper , CircularProgress , Typography } from "@mui/material";

//Interfaces && types
import { i_records_tbl_props, i_records } from '../../interface/records.interface';

//Icons
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { DeleteForeverRounded } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import NotesIcon from '@mui/icons-material/Notes';
import PostAddIcon from '@mui/icons-material/PostAdd';
import InfoIcon from '@mui/icons-material/Info';


const FormatedIvr = ( row : i_records ) => {
    var title = "Ivr press : " + row.input
    return (
        <Tooltip sx={{ textDecoration : 'underline'}} title={title}>
            <Typography>{row.input}</Typography>
        </Tooltip>
    )
}

const TableSSR = ( props : i_records_tbl_props) => { 
    return (
        <Box sx={{height : 900}}>
            { props.loading ?
                        <Box component={'div'} > 
                            <CircularProgress size={100} color="inherit" sx={{  position: 'absolute',top:'50%' , left: '50%', transform: 'translate(-50%, -50%);', width : 100 , height : 100 , marginLeft: -5 }} />  
                        </Box>

                   :
            <TableContainer component={Paper}  sx={{ maxHeight : 900 }}>
                <Table sx={{ minWidth: 500 }} aria-label="records table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width : 50}} align='center'>IVR input</TableCell>
                            <TableCell style={{ width : 50}} align='center'>Play</TableCell>
                            <TableCell style={{ width : 180}} align='center'>Call Date</TableCell>
                            <TableCell style={{ width : 160}} align='center'>From</TableCell>
                            <TableCell style={{ width : 160}} align='center'>To</TableCell>
                            <TableCell style={{ width : 100}} align='center'>Duration</TableCell>
                            <TableCell style={{ width : 160}} align='center'>Notes</TableCell>
                            <TableCell style={{ width : 120}} align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                <TableBody>
                    {props.search.offset > 0 && props.data.map((row : i_records) => (
                        <TableRow key={row.id} sx={[ props.target.call_uuid === row.call_uuid && props.isPlaying ? { backgroundColor : '#FFF9A6' } : { },
                            ]}>
                            <TableCell align='center' component="th" style={{ width : 50 , fontSize:12}}>
                            { row.input !== 0 && row.input < 10 ? <FormatedIvr {...row}/> : ''}
                            </TableCell>
                            <TableCell component="th" style={{ width : 50}}>
                                <Tooltip title={row.call_uuid}>
                                    <IconButton onClick={() => props.handlePlay(row)}>
                                        { props.target.call_uuid === row.call_uuid && props.isPlaying ? <StopIcon></StopIcon> : <PlayArrowIcon></PlayArrowIcon> }
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell component="th" style={{ width : 100}} align='center'>
                                { f_timestamp_to_date(row.add_time , 'Long')}
                            </TableCell>
                            <TableCell style={{ width: 160 }}  align='center'>
                                {handlePhone(row.from_number , props.endpoints)}
                            </TableCell>
                            <TableCell style={{ width: 160 }}  align='center'>
                                {f_format_phone_cute(row.to_number)}
                            </TableCell>
                            <TableCell style={{ width: 100 }}  align='center'>
                                {formatDuration(row.recording_duration * 1000)}
                            </TableCell>
                            <TableCell style={{ width: 100 }}  align='center'>
                                <IconButton onClick={() => props.handleEdit(row)}>
                                {row.notes.length > 0 || row.note !== '' ? <NotesIcon color='primary'></NotesIcon> : <PostAddIcon color={"primary"}></PostAddIcon>  }
                                </IconButton>
                            </TableCell>
                            <TableCell style={{ width: 100 }}  align='center'>
                                { row.events.length > 0 && 
                                    <Tooltip title={'To be implemented trace the whole call event'}>
                                        <IconButton>
                                           <InfoIcon sx={{color : 'black'}}></InfoIcon>
                                        </IconButton>
                                    </Tooltip>
                                }
                                {/* <Tooltip title={'Add a note'}>
                                    <IconButton color='primary' onClick={() => props.handleEdit(row)}><EditIcon ></EditIcon></IconButton>
                                </Tooltip> */}
                                <IconButton onClick={() => props.handleDownload(row)}>
                                    <DownloadIcon sx={{color : 'red'}}/>
                                </IconButton>
                                <IconButton onClick={() => props.setDelete(row)}>
                                    <DeleteForeverRounded></DeleteForeverRounded>
                                </IconButton>
                            </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            
                </Table>
            </TableContainer>
            }
        </Box>
    )
};

export default TableSSR;