



const validateNote = (note: string): [ boolean , string] => {
    try{
        if(!note) return [ false , 'Note is required' ]
        if(note.length === 0) return [ false , 'Please set a note' ]
        return [ true , 'Note is valid' ]
    }catch(e){
        return [ false , 'Catch on note' ]
    }
}

export { validateNote}