/*
 *
 * Filename: filter.table.pagination.tsx
 * Date: 2023-05-03
 * Description: Custom pagination component handle min max next back page , show current position. And also handle Rows per page
 * Author: Philippe Leroux @SKITSC
 *
 */

//Modules
import { IconButton , Box, Typography, TextField , MenuItem, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

//Intefaces
import { i_filter_records } from "../../interface/records.interface";
import { i_logs_filter } from "../../interface/logs.interface";

//Utility
import { ItemPerPage } from "../../utility/constant";


interface TablePaginationActionsProps {
    search : i_filter_records | i_logs_filter
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
    handleChangeRowsPerPage : (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => void;
  }
const PagesComponent = ( props : TablePaginationActionsProps ) => {
    const f_format_page_display = () => {
        var min : number = 1;
        var max : number = rowsPerPage
        if(page > 0){
            min = (page * rowsPerPage) + 1
            max = (page * rowsPerPage) + rowsPerPage + 1
            if(max > count){
                max = count
            }
        }
        return min + " / " + max
    }
    
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const totalPages = Math.ceil(count / rowsPerPage);
    const startPage = Math.max(1, page - 3); 
    const endPage = Math.min(totalPages, page + 5); 
    const handleFirstPageButton = (event: React.MouseEvent<HTMLButtonElement>,) => onPageChange(event, 0);
    const handleBackButton = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, page - 1);  
    const handleNextButton = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, page + 1);
    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement>, new_page : number ) => {
        onPageChange(event, new_page);
    }
    const handleLastPageButton = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    const page_btn_style = {  border : '1px solid #3333' , width : '35px' , display : 'inline-flex' , cursor : 'pointer' , borderRadius : '12px' , padding : '2px' , textAlign : 'center' , justifyContent : 'center' , color : "#d9b451"}
    const selectedPageStyle = {
        ...page_btn_style,
        border: '2px solid #d9b451', // Highlight the selected page
        fontWeight: 'bold',
        color : 'black'
      };
    return (
        <Box sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '1vh' }}>
            <Typography sx={{ fontSize : 14 , alignSelf : 'center' , marginRight: '1vh' }}>Rows per page:</Typography>
            
            <TextField select value={Number(props.search.offset)} onChange={props.handleChangeRowsPerPage} sx={{ marginRight : '1vh' , fontSize : 12 }}>
                {ItemPerPage.map((option : any, i : number) => (
                            <MenuItem key={i} value={option.value}>
                                {option.option}
                            </MenuItem>
                        ))}
            </TextField>
            <Typography sx={{ fontSize : 14 , alignSelf : 'center' ,  marginRight: '1vh'}}>{f_format_page_display()} of {count}</Typography>
            <Typography></Typography>
            <Box sx={{ alignSelf : 'center'}}>
                <IconButton onClick={handleFirstPageButton} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                {/* <IconButton onClick={handleBackButton}disabled={page === 0}aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton> */}
                      {Array.from({ length: endPage - startPage + 1 }, (_, i) => {
                        const pageNumber = startPage + i;
                        return (
                          <Box
                            key={pageNumber}
                            sx={pageNumber === page + 1 ? selectedPageStyle : page_btn_style}
                            onClick={(e : any) => handlePageChange(e, pageNumber - 1)} // Adjust for zero-indexed `page`
                          >
                            {pageNumber}
                          </Box>
                        );
                      })}
                {/* <Box sx={page_btn_style}>1</Box>
                <Box sx={page_btn_style}>2</Box>
                <Box sx={page_btn_style}>3</Box> */}
                {/* <IconButton onClick={handleNextButton}disabled={page >= Math.ceil(count / rowsPerPage) - 1}aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton> */}
                <IconButton onClick={handleLastPageButton}disabled={page >= Math.ceil(count / rowsPerPage) - 1}aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
       </Box>
    )
}

export default PagesComponent;