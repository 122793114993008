/*
 *
 * Filename: main.hook.tsx
 * Date: 2022/10/31
 * Description: Hook for main context
 * Author: Philippe Leroux @SKITSC
 *
 */

import React from 'react';
import { i_page } from '../interface/page.interface';
import { i_user } from '../interface/user.interface';
import { i_main_context } from './main.context';
import { NavigateFunction } from 'react-router-dom';

//Main hook for the initial context , callback for states
export const useHook = () : i_main_context => {
    const [ authenticated , setAuthenticated ] = React.useState(false)
    const [ user , setUserr ] = React.useState<i_user>({ first_name : '' , last_name : '' , id : 0 , phone : '' , email : '' , UUID : '' , password : '' , type: 'Worker' })
    const [ loaded , setLoad ] = React.useState(false)
    const [ pageContext , setPageContexts ] = React.useState<any>({page : 0, filter : '', pageSize : 25})
    const [ totalPage , setTotalPages ] = React.useState<number>(0)
    const [ menu_pos , setMenuPose ] = React.useState<number>(0)
    const setCurrentAuth = React.useCallback((currentAuth : boolean): void => {
        setAuthenticated(currentAuth)
    },[])

    const setUser = React.useCallback((currentUser : i_user): void => {
        setUserr({first_name : currentUser.first_name , last_name : currentUser.last_name , id : currentUser.id , UUID : currentUser.UUID , type : currentUser.type , password : '' , email : currentUser.email , phone : currentUser.phone})
    },[])
    const setLoaded = React.useCallback((currentload : boolean) : void => {
        setLoad(currentload)
    },[])
    const setPageContext = React.useCallback((context : i_page) : void => {
        setPageContexts({page : context.page , filter : context.filter , pageSize : context.pageSize})
    },[])
    const setTotalPage = React.useCallback((CurrentTotalPage : number) : void => {
        setTotalPages(CurrentTotalPage)
    },[])
    const setMenuPos = React.useCallback(( Currentpos : number) : void => {
        setMenuPose(Currentpos)
    },[])
    //Function to trigger on logout
    const HandleLogout = ( nav : NavigateFunction ) => {
        setCurrentAuth(false)
        setUser({first_name : '' , last_name : '' , password : '' , email : '' , phone : '' , UUID : '' , id : 0 , type : 'Worker'})
        // nav('/')
    }
    //Function to trigger on login
    const HandleLogin = ( user: i_user ) => {
        setUser(user)
        setCurrentAuth(true)
    }
    return {
        authenticated,
        loaded,
        pageContext,
        totalPage,
        user,
        setTotalPage,
        menu_pos,
        setMenuPos,
        setPageContext,
        setLoaded,
        setCurrentAuth,
        setUser,
        HandleLogin,
        HandleLogout,
    }

}