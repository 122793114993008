/*
 * Date: 2024
 * Description: Landing page when a user is validated !
 * Author: Philippe Leroux @ SKITSC
 *
 */


//Modules
import { useState , useEffect } from "react";
import { Typography , Paper , Grid2 } from "@mui/material"
import formatDuration from 'format-duration';

//Components
import { CardLatest , CardLogin , CardData , CardCount } from "../components/utils/card"
import { CCount , CData , CLogin , CLatest } from "../components/utils/template.card"

//Theme
import { ThemeProvider } from '@mui/material/styles';
import { c_theme } from "../theme/custom.theme";

//Interfaces
import { i_card_count, i_card_data, i_card_latest, i_card_login, i_dash_data } from "../interface/card.interface";

//Utility functions
import { f_format_phone_cute , f_timestamp_to_date } from "../utility/utility";
import { Fetch } from "../components/api/fetch";


const Dashboard = (props : any) => {
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ Latest , setLatest ] = useState<i_card_latest>(CLatest)
    const [ Login , setLogin ] = useState<i_card_login>(CLogin)
    const [ Data , setData ] = useState<i_card_data>(CData)
    const [ Count , setCount ] = useState<i_card_count>(CCount)
    useEffect(() => {
        const f_fetch_cards = async() =>{
            setLoading(true)
            const output = await Fetch('/api/data/dashboard' , null , 'GET' , props)
            if(output.type === 'Success'){
                const data : i_dash_data = output.data;
                var latest : i_card_latest = CLatest;
                var count : i_card_count = CCount;
                var login : i_card_login = CLogin;
                var carddata : i_card_data = CData
                if(data.latest_call.from !==  ''){
                    var latest_date = f_timestamp_to_date(data.latest_call.timestamp, 'long')
                    if(latest_date === undefined) latest_date = 0
                    latest = {
                        title : CLatest.title,
                        duration : formatDuration(Number(data.latest_call.duration) * 1000),
                        from : f_format_phone_cute(data.latest_call.from),
                        to : f_format_phone_cute(data.latest_call.to),
                        timestamp : latest_date
                    }
                }
                if(data.latest_login.email !== ''){
                    var date_login = f_timestamp_to_date(data.latest_login.date , 'long')
                    if(date_login === undefined) date_login = 0
                    login = {
                        title : CLogin.title,
                        date : date_login,
                        email : data.latest_login.email
                    }
                }
                if(data.month_call.month_call !== 0){
                    carddata = {
                        title : CData.title,
                        month_call : data.month_call.month_call,
                        week_call : data.month_call.week_call,
                        today_call : data.month_call.today_call
                    }
                }
                if(data.high_number.count !== 0){
                    count = {
                        title : CCount.title,
                        called : f_format_phone_cute(data.high_number.called),
                        caller : f_format_phone_cute(data.high_number.caller),
                        count : data.high_number.count,
                        called_count : data.high_number.called_count,
                        caller_count : data.high_number.caller_count
                    }
                }
                setLogin(login)
                setData(carddata)
                setCount(count)
                setLatest(latest)
            }else{
                props.logout(props.nav)
            }
            setLoading(false)
        }
        f_fetch_cards();
    },[])
    const initial_props = {
        loading : loading,
        setLoading : setLoading,
        CLatest : Latest,
        CLogin : Login,
        CData : Data,
        CCount : Count
    }
    return (
        <>
        <ThemeProvider theme={c_theme}>
            <Paper elevation={3} sx={{padding : '5em' , maxWidth : 1000 , display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , marginTop : '3vh'}}>
                <Typography variant="h4" align="center" sx={{marginBottom : '3vh'}}>Welcome to skitsc recall</Typography>
                <Grid2 justifyContent={"center"} container spacing={2}>
                    <Grid2 size={6}>
                        <CardLatest {...initial_props}></CardLatest>
                    </Grid2>
                    <Grid2 size={6}>
                        <CardLogin {...initial_props}></CardLogin>
                    </Grid2>
                    <Grid2 size={6}>
                        <CardData {...initial_props}></CardData>
                    </Grid2>
                    <Grid2 size={6}>
                        <CardCount {...initial_props}></CardCount> 
                    </Grid2>
                </Grid2>
            </Paper>
        </ThemeProvider>
        </>
    )
}
export default Dashboard