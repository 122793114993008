/*
 * Date: 2024
 * Description: Functionnality for user ranked admin.
 * Author: Philippe Leroux @ SKITSC
 */

//Modules
import { useContext , useState , useEffect } from 'react'
import { Typography, IconButton, Accordion , AccordionDetails , AccordionSummary} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { GridRenderCellParams } from '@mui/x-data-grid';
//Context
import { ManagementContext } from '../../context/management.context'

//Home functions
import { Fetch } from '../api/fetch'
import { f_timestamp_to_date } from '../../utility/utility'

//Components
import PromptAlert from '../utils/alert'
import SimpleGrid from '../table/simple.datagrid'
import ModalForm from '../modal/modal'
import SmtpPaper from './smtp.paper';
import AdvancedSetting from './advanced.setting.paper';
//Icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Interfaces
import { i_domain } from '../../interface/domain.interface'
import { i_alert } from '../../interface/utility.interface';
import { i_user } from '../../interface/user.interface'
import { i_smtp } from '../../interface/smtp.interface';
import { i_admin_props } from '../../interface/utility.interface';

//Constants
import { default_user , default_domain } from '../../utility/constant';

const AdminPanel = ( props : any) => {
    const { setNewLoading , setNewUsers , setNewSelected_user , selected_user , setNewSelected_domain , loading_two , setNewDomains , setNewLoading_two , loading , users , domains , selected_domain , smtp_config , setNewSmtpConfig} = useContext(ManagementContext)
    const [ alert , setAlert ] = useState<boolean>(false)
    const [ open , setOpen ] = useState<boolean>(false)
    const [ event , setEvent ] = useState<string>('')
    const [ user_rerender , setUserRerender ] = useState<number>(0)
    const [ domain_rerender , setDomainRerender ] = useState<number>(0)
    const [ alert_s , setAlertProps ] = useState<i_alert>({ msg : '' , position : 'page' , severity : 'error' , setAlert : setAlert})
    const [ title , setTitle ] = useState<string>('')
    const [ panel_user , setExpandedUser ] = useState<boolean>(true);
    const [ panel_smtp , setExpandedSmtp ] = useState<boolean>(true);
    const [ panel_domain , setExpandedDomain ] = useState<boolean>(false);
    const [ panel_adv_setting , setExpandedAdvSetting ] = useState<boolean>(false);
    const [ loading_config , setLoadingConfig ] = useState<boolean>(false)
    const handleAccordion = ( panel : 'user' | 'smtp' | 'domain' | 'adv_setting' ) => {
        panel === 'user' ? setExpandedUser(panel_user ? !panel_user : true) :
        panel === 'domain' ? setExpandedDomain(panel_domain ? !panel_domain : true) :
        panel === 'smtp' ? setExpandedSmtp(panel_smtp ? !panel_smtp : true) :
        panel === 'adv_setting' && setExpandedAdvSetting(panel_adv_setting ? !panel_adv_setting : true)
    };
    useEffect(() => {
        setNewLoading(true)
        const f_fetch_users = async( ) => {
            const output = await Fetch('/api/users' , null , 'GET' , props)
            if(output.type === 'Success'){
                setNewUsers(output.data)
            }
            setNewLoading(false)
        }
        f_fetch_users();
    },[user_rerender , setNewLoading , setNewUsers])
    useEffect(() => {
        setNewLoading_two(true)
        const f_fetch_domains = async( ) => {
            const output = await Fetch('/api/domains' , null , 'GET' , props)
            if(output.type === 'Success'){
                setNewDomains(output.data)
            }
            setNewLoading_two(false)
        }
        f_fetch_domains();
    },[domain_rerender , setNewLoading_two , setNewDomains])
    useEffect(() => {
        setLoadingConfig(true)
        const f_fetch_mail_config = async() => {
            const output = await Fetch('/api/mail' , null , 'GET' , props)
            if(output.type === 'Success'){
                setNewSmtpConfig(output.data[0])
            }else{
                console.log('Issue while loading mail config')
            }
            setLoadingConfig(false)
        }
        f_fetch_mail_config();
    },[setNewSmtpConfig])
    const HandleUser = async( param : i_user , event : 'insert' | 'update' | 'delete') => {
        setNewSelected_user(param)
        handleModal(event+'_user')
        if(event === 'delete')setTitle('Are you sure to delete' + param.email)
    }    
    const HandleDomain = async( param : i_domain , event : 'insert' | 'update' | 'delete') => {
        setNewSelected_domain(param)
        handleModal(event+'_domain')
        if(event === 'delete')setTitle('Are you sure to delete' + param.address)
    }
    const HandleSmtp = async( param : i_smtp , event : 'update') => {
        handleModal(event+'_smtp')
    }
    const handleModal = (event : string) => {
        props.handleAlertreset();
        setOpen(true)
        setEvent(event)
    }
    const handleRerender = ( type : 'domain' | 'user') => type === 'domain' ? setDomainRerender( domain_rerender + 1) : type === 'user' ? setUserRerender( user_rerender + 1) : null
    const HandleAction = () => {
        var endpoint = '/api'
        var method : 'DELETE' | 'POST' | 'PUT' = 'DELETE'
        const f_fetch_deletion = async( endpoint : string , method :  'DELETE' | 'POST' | 'PUT' ) => {
            const output = await Fetch(endpoint,null,method , props)
            if(output.type === 'Success'){
                if(event.includes('user')) handleRerender('user')
                if(event.includes('domain')) handleRerender('domain')
                handleClose();
            }else{
                setAlertProps({ msg : output.message , position : 'modal' , severity : 'error' , setAlert : setAlert})
                setAlert(true)
            }
        }
        if(event === 'delete_user')  endpoint += '/users/'+selected_user.id
        if(event === 'delete_domain')  endpoint += '/domains/'+selected_domain.id
        if(endpoint !== '/api') f_fetch_deletion(endpoint , method)
    }
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const user_columns: GridColDef[] = [
        { field: 'email', headerName: 'email', width: 150 },
        { field: 'first_name', headerName: 'First name', width: 150 },
        { field: 'last_name', headerName: 'Last name', width: 150 },
        { field: 'phone' , headerName : 'Phone' , width : 150},
        { field: 'type', headerName: 'Rank', width: 150 },
        { field: 'action', headerName: 'Action', width: 150 ,  renderCell: (params: GridRenderCellParams) => (
          <>
            <IconButton onClick={() => HandleUser(params.row , 'update')}>
                <UpdateIcon fontSize='large' sx={{cursor: 'pointer' , color : '#d9b451;', marginRight :'2wh'}} />
            </IconButton>
            <IconButton onClick={() => HandleUser(params.row , 'delete')}>
                <DeleteForeverIcon fontSize='large' sx={{ cursor : 'pointer' , color : 'crimson'}}/>
            </IconButton>
          </>
        ), },
      ];
    const domain_columns: GridColDef[] = [
        { field: 'timestamp', headerName: 'Date added', width: 150 , renderCell: (params : GridRenderCellParams) => f_timestamp_to_date(params.row.timestamp , 'long')},
        { field: 'address', headerName: 'Address allowed', width: 250 },
        { field: 'note', headerName: 'Note', width: 350 },
        { field: 'action', headerName: 'Action', width: 150 ,  renderCell: (params: GridRenderCellParams) => (
          <>
            <IconButton onClick={() => HandleDomain(params.row , 'update')}>
                <UpdateIcon fontSize='large' sx={{cursor: 'pointer' , color : '#d9b451;', marginRight :'2wh'}}/>
            </IconButton>
            <IconButton onClick={() => HandleDomain(params.row , 'delete')}>
                <DeleteForeverIcon fontSize='large' sx={{ cursor : 'pointer' , color : 'crimson'}} />
            </IconButton>
          </>
        ), },
      ];
    const admin_props : i_admin_props = {
        open : open,
        Action : HandleAction,
        handleClose : handleClose,
        handleOpen : handleOpen,
        event : event,
        handleRerender : handleRerender,
        setAlert : props.setAlert,
        setAlertmsg : props.setAlertmsg,
        title : title
    }
    return (
        <div>
            {alert ? <PromptAlert {...alert_s}></PromptAlert> : null }
            <Accordion elevation={5} expanded={panel_user} onChange={() => handleAccordion('user')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography sx={{ width: '33%', flexShrink: 0 , fontWeight:900}}>User management</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Create , update and delete new users.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display : 'flex'}}>
                        <Typography sx={{ textDecoration : 'underline' , fontSize:20 , alignSelf : 'center'}}>Users registered </Typography>                
                        <IconButton color={'primary'} size={'large'} onClick={() => HandleUser(default_user , 'insert')}>
                            <PersonAddIcon/>
                        </IconButton>       
                    </div>
                    <div style={{ height : 375 , width : '100%' }}>
                        <SimpleGrid loading={loading} rows={users} columns={user_columns} pageSize={5} rowsPerPageOptions={[5]}/>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion elevation={5} expanded={panel_smtp} onChange={ () => handleAccordion('smtp')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                    <Typography sx={{ width: '33%', flexShrink: 0 , fontWeight:900}}>Smtp configuration </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Test mail box configuration.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SmtpPaper HandleModal={HandleSmtp} loading={loading_config}></SmtpPaper>
                </AccordionDetails>
            </Accordion>
            <Accordion elevation={5} expanded={panel_domain} onChange={() => handleAccordion('domain')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel2bh-header">
                    <Typography sx={{ width: '33%', flexShrink: 0 , fontWeight:900}}>Domains allowed</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Create , update and delete new domains allowed by the app.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display : 'flex'}}>
                        <Typography sx={{ textDecoration : 'underline' , fontSize:20 , alignSelf : 'center'}}>Domains allowed by the api :</Typography>
                        <IconButton color={'primary'} size={'large'} onClick={() => HandleDomain(default_domain , 'insert')}>
                            <AddToQueueIcon/>
                        </IconButton>
                    </div>
                    <div style={{ height : 375 , width : '100%' }}>
                        <SimpleGrid loading={loading_two} rows={domains} columns={domain_columns} pageSize={5} rowsPerPageOptions={[5]}/>
                    </div>

                </AccordionDetails>
            </Accordion>
            <Accordion elevation={5} expanded={panel_adv_setting} onChange={() => handleAccordion('adv_setting')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
                    <Typography sx={{ width: '33%', flexShrink: 0 , fontWeight:900}}>Advanced settings</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Execute script to populate api</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <AdvancedSetting></AdvancedSetting>
                </AccordionDetails>
            </Accordion>
        {open ? <ModalForm {...admin_props}></ModalForm> : null}
        </div>
    )
}
export default AdminPanel