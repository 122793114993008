/*
 * Date: 2024
 * Description: Setting page of the app ( 'not done yet' )
 * Author: Philippe Leroux @ SKITSC
 */

//Modules
import { Paper , Container , Typography , Button} from "@mui/material"
import { useContext, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';

//Custom themes
import { c_theme } from "../theme/custom.theme";


//Interfaces && types
import { i_alert } from "../interface/utility.interface";

//Components
import ModalForm from "../components/modal/modal";
import PromptAlert from "../components/utils/alert";
import AdminPanel from "../components/setting/admin_panel";

//Context
import { MainContext } from "../context/main.context";
import { ManagementContext } from "../context/management.context";
import { ManagementHook } from "../context/management.hook";

const Setting = ( props : any ) => {
    const [ openModal , setModal ] = useState<boolean>(false);
    const [ modalEvent , setEvent ] = useState<string>('')
    const [ alert , setAlert ] = useState<boolean>(false)
    const { user } = useContext(MainContext)
    const [ alertmsg , setAlertmsg ] = useState<i_alert>({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'});
    const handleOpen = () => setModal(true);
    const hook = ManagementHook();
    const handleClose = () => {
        setModal(false);
    }    
    const handleTransition = ( event : string ) => {
        setEvent(event)
        handleAlertreset();
        setModal(true)
    }
    const handleAlertreset = () => {
        setAlert(false);
        setAlertmsg({msg : '' , severity : 'error' , setAlert : setAlert , position : 'modal'})
    }
    const modal_props = {
        open : openModal,
        handleOpen : handleOpen,
        handleClose : handleClose,
        setAlertmsg : setAlertmsg,
        setAlert: setAlert,
        event : modalEvent,
        alertmsg : alertmsg,
        alert : alert,
        msg : alertmsg.msg,
        severity : alertmsg.severity,
        position : alertmsg.position,
        handleTransition : handleTransition,
        handleAlertreset : handleAlertreset,
        user : user,
        title : 'Change credentials'
    }

    return (
        <ThemeProvider theme={c_theme}>
            <ManagementContext.Provider value={hook}>
                {alert ? <PromptAlert {...modal_props}></PromptAlert> : null }
                <Container sx={{ marginTop:5}}>
                    <>
                        {user.type !== 'Admin' ?
                            <Paper sx={{height:'80vh' , paddingBottom:'2vh' , paddingTop:'2vh' , paddingLeft:'2vw' , paddingRight:'2vw'}} elevation={4}>
                                <Typography sx={{ textAlign:'center' , fontSize:'24px' , marginBottom:'3vh'}}>Change your credentials</Typography>
                                    <Paper sx={{ padding:'3vh' , marginBottom:'3vh'}} elevation={5}>
                                        <Typography sx={{ marginLeft:'6vw',fontSize:'20px' ,display:'inline' , fontWeight:'800'}}>Your password : </Typography>
                                        <Typography sx={{ marginLeft:'6vw',fontSize:'24px' , display:'inline' , verticalAlign:'middle'}}>************</Typography>
                                        <Button onClick={() => handleTransition('password')} sx={{ float:'right' , fontWeight:'900' , display:'inline'}}variant="contained" color='primary'>Change password</Button>
                                    </Paper>
                                <Paper sx={{ padding:'3vh'}} elevation={5}>
                                    <Typography sx={{ marginLeft:'6vw',fontSize:'20px' ,display:'inline' , fontWeight:'800'}}>Your email : </Typography>
                                    <Typography sx={{ marginLeft:'6vw',fontSize:'24px' , display:'inline' , verticalAlign:'middle'}}>{user.email}</Typography>
                                    <Button onClick={() => handleTransition('email')} sx={{ float:'right' , fontWeight:'900' , display:'inline'}}variant="contained" color='primary'>Change your email</Button>
                                </Paper>   
                            </Paper>
                        :          
                            <AdminPanel {...modal_props}></AdminPanel>
                        }
                    </>
                   {openModal ? <ModalForm {...modal_props}></ModalForm> : null}
                   
                </Container>
            </ManagementContext.Provider>
        </ThemeProvider>
 
    )
}
export default Setting