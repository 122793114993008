/*
 *
 * Filename: login.tsx
 * Date: 2022-11-03
 * Description: login integrates alot of concepts.
 * Author: Philippe Leroux @ SKITSC
 *
 */
//Packages
import { useState , useEffect} from 'react';
import { Paper , Box , Link , Checkbox , FormControlLabel , CssBaseline , Typography , Container , Grid2 } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate , useLocation } from 'react-router-dom';
import { f_get_local_key, f_kill_storage, f_set_local_key } from '../utility/utility';
//Interfaces
import { i_alert , i_error_input } from '../interface/utility.interface';
//Components
import { f_fetch_login } from '../components/api/fetch';
import Copyright from '../components/copyright';
import '../styles/login.css'
import PromptAlert from '../components/utils/alert';
import { c_theme } from '../theme/custom.theme';
import CustomInput from '../components/form/custom.input';
import { field_style } from '../components/styles/styles';
import { m_valid_email , m_valid_password } from '../components/utils/field.validation';

const Login = (props : any) => {
    const [ err_email , setErremail ] = useState<i_error_input>({error : false , msg : ''});
    const [ err_password , setErrpassword] = useState<i_error_input>({error : false , msg : ''});
    const [ remember , setRemember ] = useState<boolean>(false)
    const [ alert , setAlert ] = useState<boolean>(false)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ e_focus , setEFocus ] = useState<boolean>(false)
    const [ p_focus , setPFocus ] = useState<boolean>(false)
    const [ alert_msg , setAlertmsg ] = useState<i_alert>({msg : '' , severity : 'error' , setAlert : setAlert , position : 'login'});
    const [ email , setEmail ] = useState('');
    const [ password , setPassword ] = useState('');
    let navigate = useNavigate();
    let location = useLocation();
 
    useEffect(() => {   
        let from = location.state?.from?.pathname || "/dashboard";
        if(props.authenticated) navigate(from, { replace: true });
    },[props.authenticated])
    useEffect(() => {
        if(props.offline) navigate('/offline', { replace: true });
    },[props.offline])
    useEffect(() => {
        var value = f_get_local_key('email')
        if(value != null){
            setEmail(value)
            setPFocus(true)
            setRemember(true)
        }else{
            setEFocus(true)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const f_save_local = () => {
        if(!remember){
            f_set_local_key( 'email' , email , 600000000 )
            setRemember(true);
        }else{
            setRemember(false)
            f_kill_storage('email')
        }
    }
    const handleRedirect = () => {
        navigate('/forgotpassword')
    }
    const handleSubmit = async(event : any) => {
        event.preventDefault();
        const valid_email = m_valid_email(email)
        const valid_password = m_valid_password(password);     
        const data = {
            email : email,
            password : password
        }
        if(valid_email === '' && valid_password === '' ){
            setLoading(true)
            const { output , headers } = await f_fetch_login(data);
            setLoading(false)
            if(output.type === 'Success'){
                props.HandleLogin(output.data)
            }else{
                if(!remember)setEmail('');
                setPassword('');
                var remaining_attempts : number = 0
                var rep : string = ''
                remaining_attempts =  headers !== undefined && headers['ratelimit-remaining']
                if(remaining_attempts > 0) {
                    rep = 'Password or email invalid '+remaining_attempts+' attempts remaining!'
                } else{
                    rep = 'Too many request please try again later...'
                }
                setAlertmsg({msg : rep , severity : 'error' , setAlert : setAlert , position : 'login'})
                setAlert(true)
            }
        }else{
            if(valid_email !== '') setErremail({error : true , msg : valid_email })
            if(valid_password !== '') setErrpassword({ error : true , msg : valid_password})
        }
      };
    const handleAlertreset = () => {
        setAlert(false);
        setAlertmsg({msg : '' , severity : 'error' , setAlert : setAlert , position:'login'})
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        if(remember) f_set_local_key('email',event.target.value,600000)
        if(err_email) setErremail({error : false , msg : ''});
        handleAlertreset();
    };
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        if(err_password) setErrpassword({ error : false , msg : '' });
        handleAlertreset();
    };
   return (
    <> { !props.authenticated &&
    <ThemeProvider theme={c_theme}>
      <Container style={{height : '100vh'}} component="main" maxWidth="sm">
        {alert ? <PromptAlert {...alert_msg}></PromptAlert> : null}
        <CssBaseline />
        <Box component={'div'} height="20vh"></Box>
        <Paper sx={{ height : 550 , padding : '3vh'}} elevation={4}>
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <img src='/img/sm-logo.png' alt='skitc logo' className='img'/>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} autoComplete={'on'} sx={{ mt: 1 }}>
            <CustomInput value={email} err={err_email} onChange={handleChangeEmail} style={field_style} type={'email'} fullWidth={true} name={'email'} required={true} focus={e_focus} complete={'email'}/>
            <CustomInput value={password} err={err_password} onChange={handleChangePassword} style={field_style} type={'password'} fullWidth={true} name={'password'} required={true} focus={p_focus} complete={'password'}/>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
              onClick={() => f_save_local()}
              checked={remember}
            />
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color='primary'
                loading={loading}
                sx={{ mt: 3, mb: 2 }}
                >
                Sign In
            </LoadingButton>
            <Grid2 container>
              <Grid2 >
                <Link onClick={handleRedirect} variant="body2">
                  Forgot password?
                </Link>
              </Grid2>
            </Grid2>
          </Box>
        </Box>
        </Paper>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    }</>
   ) 
}

export default Login