/*
 *
 * Filename: utility.ts
 * Date: 2022-11-04
 * Description: Reusable function thought the app
 * Author: Philippe Leroux @ SKITSC
 *
 */
import { GridValueFormatter } from "@mui/x-data-grid/models"
//Interface && types
import { i_promise } from "../interface/utility.interface"
import { i_endpoint } from "../interface/endpoint.interface"

const f_generate_header = (method : "POST" | "GET" | 'PUT' | 'PATCH' | 'DELETE' , body : any , credentials : RequestCredentials | undefined) => {
    const headers = {
        method: method,
        credentials: credentials,
        headers: { 
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body : body == null ? null : JSON.stringify(body)
    }
    return headers
}
const f_generate_empty_promise = () => {
    const promise : i_promise = {
        message : '',
        type : 'Success',
        data : []
    }
    return promise
}
const findEndpoint = ( number : string , type : 'in' | 'out' | 'render' , array : i_endpoint[] ) : string => {
    if(type === 'in'){
        const res = array.find(( obj : i_endpoint ) => obj.sip_url === number)
        if(res !== undefined && res.pair_number !== ''){
            return res.pair_number
        }else{
            return number
        }
    }
    if(type === 'out'){
        const res = array.find(( obj : i_endpoint ) => obj.pair_number === number)
        if(res!== undefined){
            return res.sip_url
        }else{
            return number
        }
    }else{
      return handlePhone(number , array)
    }
}
const f_set_local_key = (key : string, input_value : string, time : number) => {
    const now = new Date()
    const item = {
        value: input_value,
        expiry: now.getTime() + time,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const f_get_local_key = (key : string) => {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) return null
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        f_kill_storage(key)
        return null
    }
    return item.value
}
const f_kill_storage = ( key : string ) => {
    localStorage.removeItem(key)
}

const delay = (ms : number) => new Promise(res => setTimeout(res, ms));

const f_format_phone_cute = ( ugly_phone : string | null) => {
    if(ugly_phone === null){
        return 'none'
    }else{
        if(ugly_phone.includes('@')){
           var tear = ugly_phone;
           tear = tear.split('sip:').join('')
           tear = tear.split('@')[0]
           tear = tear.replace(/[0-9]/g, '');
           return tear
        }else{
            var cute_phone = ugly_phone;
            var l = cute_phone.length
            if(l > 7 && l === 11)cute_phone = ugly_phone.replace(/(\d{1})(\d{3})(\d{3})(\d{1,4})$/, "$1-($2)-$3-$4")
            if(l > 7 && l === 10)cute_phone = ugly_phone.replace(/(\d{3})(\d{3})(\d{1,4})$/, "($1)-$2-$3")
            if(l > 4 && l <= 7)cute_phone = ugly_phone.replace(/(\d{1})(\d{3})(\d{1,3})$/, "$1-($2)-$3")
            if(l > 1 && l <= 4)cute_phone = ugly_phone.replace(/(\d{1})(\d{1,3})$/, "$1-($2)")
            if(l === 1)cute_phone = ugly_phone.replace(/(\d{1})$/, "$1-")
            return cute_phone 
        }
    }
}
const handlePhone = (ugly_phone : string | null , endpoints : i_endpoint[]) => {
    if(ugly_phone === null){
        return 'none'
    }else{
        if(ugly_phone.includes('@')){
            const find = endpoints.find(endpoint => endpoint.sip_url === ugly_phone)
            if(find !== undefined && find.pair_number !== '') return find.pair_number
            var tear = ugly_phone;
            tear = tear.split('sip:').join('')
            tear = tear.split('@')[0]
            tear = tear.replace(/[0-9]/g, '');
            return tear
        }else{
            var cute_phone = ugly_phone;
            var l = cute_phone.length
            if(l > 7 && l === 11)cute_phone = ugly_phone.replace(/(\d{1})(\d{3})(\d{3})(\d{1,4})$/, "$1-($2)-$3-$4")
            if(l > 7 && l === 10)cute_phone = ugly_phone.replace(/(\d{3})(\d{3})(\d{1,4})$/, "($1)-$2-$3")
            if(l > 4 && l <= 7)cute_phone = ugly_phone.replace(/(\d{1})(\d{3})(\d{1,3})$/, "$1-($2)-$3")
            if(l > 1 && l <= 4)cute_phone = ugly_phone.replace(/(\d{1})(\d{1,3})$/, "$1-($2)")
            if(l === 1)cute_phone = ugly_phone.replace(/(\d{1})$/, "$1-")
            return cute_phone 
        }
    }
}
//Returns if today date fit object date.
const f_is_Today = (date : Date) => {
    const today = new Date();
    if (today.toDateString() === date.toDateString()) return true;
    return false;
}
const f_is_Day = (param : number, date :Date) =>{
    var today = new Date();
    today.setDate(today.getDate() - param);
    if ((today.toDateString()) === date.toDateString())return true;
    return false;
}
const f_is_Year = (date : Date) => {
    const today = new Date();
    if (today.getFullYear() === date.getFullYear())return true;
    return false;
}
const pad = (value : number) => {
    if(value < 10) {
        return '0' + value;
    } else {
        return value;
    }
}
const f_timestamp_to_date = ( timestamp : number | GridValueFormatter | string , type : string ) => {
    if(timestamp == null){
        return 0
    }else{
        if(Number(timestamp)){
            var timez : number = Number(timestamp)
            const UNIX_timestamp = new Date(timez * 1000)
            const is_today = f_is_Today(UNIX_timestamp)
            const is_year = f_is_Year(UNIX_timestamp)
            var time : string = '';
            var a : Date = new Date(UNIX_timestamp);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year : number = a.getFullYear();
            var month : string = months[a.getMonth()];
            var short_month : number = a.getMonth() + 1;
            var date : number = a.getDate();
            var hour : number = a.getHours();
            var min : string | number = pad(a.getMinutes());
            if(type !== undefined && type === 'short'){
                if(!is_today){
                    var str_year = String(year)
                time =  date + '/' + short_month + '/' + str_year.slice(2); 
                }else{
                    var moment = 'A.M'
                    if(hour > 12){
                        moment = 'P.M'
                        hour = hour - 12
                    }
                    time = hour + ':' + min + ' ' + moment;
                }
                return time
            }

            if(is_today === undefined || is_year === undefined){
                time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
                return time
            }
            if(is_today && is_year){
                time = "Today " +hour + ':' + min;
                return time
            }
                time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
            return time; 
        }
        }
     
}
const f_find_folder = (date : number) => {
        var dateObj = new Date(date * 1000);
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
        var myfolder = '/uploads/'+year+'/'+month+'/'+day+'/';
        return myfolder
}
const f_return_endpoint = () => {
    var location = window.location.hostname;
    var protocol = window.location.protocol;
    var port = '5000'
    var str_build = protocol + "//" + location + ":" + port
    return str_build
}
const f_return_address = () => {
    var location = window.location.hostname;
    var str_build = location;
    return str_build
}

export { f_generate_header , findEndpoint, f_generate_empty_promise , handlePhone, delay , f_get_local_key , f_set_local_key , f_kill_storage , f_format_phone_cute , f_timestamp_to_date , f_is_Day , f_find_folder , f_return_endpoint , f_return_address }
    