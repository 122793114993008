/*
 *
 * Filename: fetch.tsx
 * Date: 2022-11-03
 * Description: fetch formula functions.
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Utils
import { f_generate_header , f_generate_empty_promise , delay } from '../../utility/utility';
import { i_promise } from '../../interface/utility.interface';

//Interfaces
import { i_auth_context, i_main_context} from '../../context/main.context';
interface login_data {
    email : string;
    password : string;
}

const getHeaders = (headers: any) => {
    let headerObj: any = {};
    const keys = headers.keys();
      let header = keys.next();
      while (header.value) {
        headerObj[header.value] = headers.get(header.value);
        header = keys.next();
      }
    return headerObj;
  };
const f_fetch_login = async( data : login_data ) => {
    const empty_promise : i_promise = f_generate_empty_promise();
    var header : any
    try{
        await delay(1000)
        
        const head = f_generate_header('POST' , data , 'include')
        const output = await fetch(process.env.REACT_APP_API_URL+'/api/login', head)
            .then((response) => { 
                 header = getHeaders(response.headers)
                 return response.json() })
            .then((response : i_promise) => { return response })
            .catch((err) => {
                empty_promise.type = 'Error'
                empty_promise.message = 'Api error'
                return empty_promise
            })
        return  { output : output , headers : header }
    }catch(err){
        console.log(err)
        return { output : empty_promise , headers : header }
    }
}
const Fetch = async ( path : string , data : any , method : 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE' , Context : i_main_context | i_auth_context ) => {
    const empty_promise : i_promise = f_generate_empty_promise();
    try{
        const head = f_generate_header(method , data , 'include')
        const output = await fetch(process.env.REACT_APP_API_URL+path, head)
            .then((response) => { return response.json() })
            .then((response : i_promise) => { return response })
            .catch((err) => {
                empty_promise.type = 'Error'
                empty_promise.message = 'Api error'
                return empty_promise
            })
        if(output.type === 'Unauthorized')if(Context.authenticated)Context.setCurrentAuth(false)
        return output  
    }catch(err){
        console.log(err)
        return empty_promise
    }

}
const Fetch_File = async ( path : string , method : 'GET' ) => {
    try{
        const head = f_generate_header(method , null , 'include')
        const output = await fetch(process.env.REACT_APP_API_URL+path, head)
        return output  
    }catch(err){
        console.log(err)
    }

}
const f_logout = async () => {
    const empty_promise : i_promise = f_generate_empty_promise();
    try{
        const output = await fetch(process.env.REACT_APP_API_URL+"/api/logout", {
            method: 'get',
            credentials: 'include',
            redirect: "follow"
        })
        .then((res) => { return res.json() })
        .then(( res : i_promise) => { return res })
        .catch(err => {
            empty_promise.type = 'Error'
            empty_promise.message = 'Api error'
            return empty_promise
        });
        return output
    }catch(err){
        console.log(err)
        return empty_promise
    }
}

export { Fetch , Fetch_File , f_fetch_login , f_logout}